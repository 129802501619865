import React, {useEffect, useState, useRef} from 'react';
import {Helmet} from "react-helmet";
import Navigation from "../components/Layout/Navigation/Navigation";
import Footer from "../components/Layout/Footer";
import Auth from '../components/Admin/Auth';
import AuthService from "../services/auth.service";
import AdminService from '../services/admin.service';
import Events from "../components/Admin/Section/events";
const schoolName = 'collegelyceelafayette';

const Admin = () => {

    /* ----------------- AUTHENTICATION -------------------- */
    useEffect(() => {
        AuthService.checkTokenAdmin()
            .then(() => setIsAuthenticated(true))
            .catch(() => setIsAuthenticated(false))
    },[])
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const Disconnect = () => {
        AuthService.logout();
        setIsAuthenticated(false);
    }

    /* ----------------- SECTION -------------------- */
    const [section, setSection] = useState('');
    const backSection = () => {
        if (section.includes('photos/'))
            setSection('photos')
        else
            setSection('');
    }
    /* ----------------- RESSOURCES -------------------- */
    const [inputRessourcesError, setInputRessourcesError] = useState({
        index: -1,
        text: ''
    });
    const [inputRessourcesSuccess, setInputRessourcesSuccess] = useState({
        index: -1,
        text: ''
    });
    const [inputRessourcesLoadingIndex, setInputRessourcesLoadingIndex] = useState(null);


    const ressourcesEcole = [
        {directory: 'ecole', name:'menu-cantine', title: 'Menu de la cantine', ref: useRef(null)},
    ]

    const removeFile = async(event, inputIndex, ressourceName, directory) => {
        setInputRessourcesError({
            index: -1,
            text: ''
        });
        setInputRessourcesSuccess({
            index: -1,
            text: ''
        });
        setInputRessourcesLoadingIndex(inputIndex);
        await AdminService.removeFile(schoolName, ressourceName, directory)
            .then((res) => {
                if (res && res.data){
                    setTimeout(() => {
                        setInputRessourcesLoadingIndex(null)
                        setInputRessourcesSuccess({
                            index: inputIndex,
                            text: 'Fichier supprimé avec succès.'
                        })
                    }, 500)
                }
            })
            .catch((err) => {
                if (err?.response?.data?.error) {
                    setTimeout(() => {
                        setInputRessourcesLoadingIndex(null)
                        setInputRessourcesError(   {
                            index: inputIndex,
                            text: err?.response?.data?.error
                        })
                    }, 500)
                }
            })
    }
    // Directly upload file when file change
    const handleChangeFileRessources = async(event, inputIndex, ressourceName, directory) => {
        setInputRessourcesError({
            index: -1,
            text: ''
        });
        setInputRessourcesSuccess({
            index: -1,
            text: ''
        });
        setInputRessourcesLoadingIndex(inputIndex);
        let bodyFormData = new FormData();
        bodyFormData.append(ressourceName, event.target.files[0])
        await AdminService.sendInputRessources(bodyFormData, schoolName, directory)
            .then((res) => {
                if (res && res.data && res.data.message === 'Success'){
                    setTimeout(() => {
                        setInputRessourcesLoadingIndex(null)
                        setInputRessourcesSuccess({
                            index: inputIndex,
                            text: 'Fichier ajouté avec succès'
                        })
                    }, 500)
                }


            })
            .catch(() => {
                setTimeout(() => {
                    setInputRessourcesLoadingIndex(null)
                    setInputRessourcesError(   {
                        index: inputIndex,
                        text: "Une erreur s'est produite lors de l'ajout du fichier. Merci de vérifier que votre fichier est inférieur à 8MB et qu'il n'est pas corrompu."
                    })
                }, 500)
            })
    }



    return (
        <>
            <Navigation />
            <div className='container-custom page d-flex justify-content-center'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>CIL Administration</title>
                    <meta name="description" content="Le Collège Lycée Lafayette offre cursus bilingue et pluridisciplinaire : éducation bi-culturelle, une pédagogie différenciée, la confiance et l'estime de soi." />
                </Helmet>
                <div className='content fadeInEarth' style={{maxWidth: '700px'}}>
                    {isAuthenticated ?
                        <div className='row mb-3' >
                            <div className='col-6'>
                                <button className='btn btn-sm btn-danger w-100' onClick={Disconnect}>Déconnexion</button>
                            </div>
                            <div className='col-6'>
                                <button
                                    className='btn btn-sm btn-secondary w-100'
                                    onClick={backSection}>
                                    Retour
                                </button>
                            </div>
                           <div className='col-12'>
                               <hr/>
                           </div>
                        </div>: null}
                    {!isAuthenticated ? <Auth setAuth={setIsAuthenticated}/> : null}
                    {
                        isAuthenticated  && !section
                            ?
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='special-title-admin' style={{maxWidth: '400px'}} role='button' onClick={() => setSection('ressources')}>
                                        Gérer les ressources
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='special-title-admin' style={{maxWidth: '400px'}} role='button' onClick={() => setSection('events')}>
                                        Gérer les événements
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                    {
                        section === 'ressources'
                            ?
                            <div className='ressources'>
                                <p style={{maxWidth: '500px', margin: '0 auto !important', textAlign: 'center', marginBottom: '2em'}}>Merci d'envoyer uniquement des fichiers au format PDF. Une fois le fichier téléchargé, il sera automatiquement déployé sur le site internet.</p>
                                <ul className="list-group">
                                    {
                                        ressourcesEcole.map((ressource, index) => {
                                            return (
                                                <li key={index} className="list-group-item">
                                                    <div className='row align-items-center'>
                                                        <div className='col-6'>{ressource.title}</div>
                                                        <div className='col-6 text-right'>
                                                            <button onClick={() => {ressource.ref.current.click();}} className='btn btn-primary w-75 mb-1   '>
                                                                {
                                                                    inputRessourcesLoadingIndex === index
                                                                        ?  <div className="spinner-border text-light" style={{width: '30px', height: '30px'}} role="status" />
                                                                        : 'Ajout fichier'
                                                                }
                                                            </button>
                                                            <button onClick={(event) => removeFile(event, index, ressource.name, ressource.directory)} className='btn btn-danger w-75'>
                                                                {
                                                                    inputRessourcesLoadingIndex === index
                                                                        ?  <div className="spinner-border text-light" style={{width: '30px', height: '30px'}} role="status" />
                                                                        : 'Supprimer'
                                                                }
                                                            </button>
                                                            <input accept="application/pdf" ref={ressource.ref} onChange={(event) => handleChangeFileRessources(event,index, ressource.name, ressource.directory)} className='d-none' type="file" name="file" />
                                                        </div>
                                                    </div>
                                                    {inputRessourcesError.index === index ? <><br /><div className='alert alert-danger'>{inputRessourcesError.text}</div></> : null }
                                                    {inputRessourcesSuccess.index === index ? <><br /><div className='alert alert-success'>{inputRessourcesSuccess.text}</div></> : null }
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            : null
                    }
                    {
                        section === 'events'
                        ? <Events /> : null
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Admin;