import React, {useState } from 'react';
import AuthService from '../../../services/auth.service';

const Auth = ({setAuth}) => {
    const [loginValues, setLoginValues] = useState({
        username: {
            error: '',
            value: 'collegelyceelafayette'
        },
        password: {
            error: '',
            value: ''
        }
    })
    const [loading, setLoading] = useState(false);
    const handleUpdateValues = (event) => {
        event.preventDefault();
        let name = event.target.name;
        setLoginValues(() => {
            return ({
                ...loginValues,
                [name]: {
                    error: '',
                    value: event.target.value
                }
            })
        })
    }
    const submitLogin = () => {
        let error = false;
        if (!loginValues.username.value.length) {
            error = "Merci de renseigner le nom d'utilisateur et le mot de passe"
            setLoginValues(() => ({...loginValues, 'username': {...loginValues.username, error: error}}))
        }
        if (!loginValues.password.value.length) {
            error = "Merci de renseigner le nom d'utilisateur et le mot de passe"
            setLoginValues(() => ({...loginValues, 'password': {...loginValues.password, error: error}}))
        }
        if (!error) {
            setLoading(true);
            setTimeout(async () => {
                await AuthService.login(loginValues.username.value, loginValues.password.value)
                    .then(() => {
                        setAuth(true);
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                        setLoginValues(() => ({...loginValues, 'password': {...loginValues.password, error: "Nom d'utilisateur ou mot de passe invalide"}}))
                    })
            }, 2000)

        }

    }
    return (
        <div className='loginContainer'>
            <p className='text-center'>Espace Administration</p>
            <div className='loginWrapper'>
                {
                    loginValues.username.error || loginValues.password.error
                        ?
                            <div className='alert alert-danger'>
                                {loginValues.username.error ? loginValues.username.error : loginValues.password.error}
                            </div>
                        : null
                }
                <div className="form-group mb-2">
                    <input
                        value={loginValues.username.value ? loginValues.username.value : ''}
                        type="text"
                        disabled
                        placeholder="Nom d'utilisateur"
                        onChange={handleUpdateValues}
                        className="form-control"
                        name="username"
                    />
                </div><br/><br/>
                <div className="form-group mb-2">
                    <input
                        value={loginValues.password.value ? loginValues.password.value : ''}
                        type="password"
                        placeholder="Mot de passe"
                        onChange={handleUpdateValues}
                        className="form-control"
                        name="password"
                    />
                </div>
                <button
                    type="button"
                    onClick={submitLogin}
                    className="btn btn-primary">
                    {
                        loading
                            ?
                            <div className="spinner-border text-light" role="status" />
                            : 'Connexion'
                    }
                </button>
            </div>
        </div>
    )
}

export default Auth;