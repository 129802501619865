import * as React from 'react';
import {default as DatePicker, registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AdminService from './../../../services/admin.service'
import fr from 'date-fns/locale/fr';
import moment from 'moment';
import 'moment/locale/fr'
import {useEffect} from "react";
const school_name = process.env.SCHOOL_NAME;
registerLocale('fr', fr)


const trashIcon = (
    <svg xmlns="http://www.w3.org/2000/svg"  fill="red" className="bi bi-trash-fill"
         viewBox="0 0 16 16">
        <path
            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
    </svg>
)

const Events = () => {
    const [section, setSection] = React.useState('')
    const [startDate, setStartDate] = React.useState(new Date());
    const [error, setError] = React.useState('')
    const [sucess, setSuccess] = React.useState('');
    const [getEvents, setGetEvents] = React.useState([])

    const [inputEvent, setInputEvents] = React.useState({
        title: '',
        date: startDate,
        school_name: school_name
    })

    const handleAddEvent = async() => {
        setSuccess('');
        setError('');
        if (!inputEvent?.title?.length) {
            setError("Titre requis pour l'événement")
            return;
        }
        try {
            await AdminService.eventCalendar_add({
                ...inputEvent,
                date: moment(startDate).format('YYYY-MM-DD HH:mm')
            })
            setSuccess('Ajouté avec succès')

        } catch (e){
            setError("Erreur lors de l'ajout de l'événement")
        }

    }

    const handleInputChange = (event) => {
        let {value, name} = event.target;
        setInputEvents((prevState) => {return {...prevState, [name]: value}})
    }


    useEffect(() => {
        if (section === 'supprimer')
            handleGetEvents();
    }, [section])
    const handleGetEvents = async() => {
        setSuccess('');
        setError('');
        try {
            let response = await AdminService.eventCalendar_get(school_name);
            let events = response?.data;
            if (events.length){
                events.sort(function(a,b){
                    return new Date(b.date) - new Date(a.date);
                });
                setGetEvents(events)
            }
            else setGetEvents([])


        } catch (e){
            setError("Erreur lors de la récupération des événements")
        }
    }

    const handleDeleteEvent = async(event_id) => {
        setSuccess('');
        setError('');
        try {
            await AdminService.eventCalendar_delete(event_id);
            await handleGetEvents();

        } catch (e){
            setError("Erreur lors de la suppression de l'événement")
        }
    }

    const showError = (
        <div className="alert alert-danger" role="alert">
            {error}
        </div>
    )

    const showSuccess = (
        <div className="alert alert-success" role="alert">
            {sucess}
        </div>
    )

    const EventsList = () => {
        if (getEvents.length){
            return getEvents.map((object, key) => {
                return  (
                    <li key={key} className="list-group-item d-flex align-items-center justify-content-between">
                        <div>
                            <strong>{object.title}</strong><br/>
                            <em>{moment(object.date).locale('fr').format('dddd, D MMMM YYYY HH:MM')}</em>
                        </div>
                        <div onClick={async() => {
                            await handleDeleteEvent(object.event_id)
                        }} style={{fill: 'red',width: '20px', height: '20px', cursor: 'pointer'}}>
                            {trashIcon}
                        </div>


                    </li>
                )
            })
        }
        else return (
            <div><br/>Aucun événement à afficher</div>
        )
    }
    return (
        <>
            {error && showError}
            {sucess && showSuccess}
            {
                !section
                    ?
                        <div style={{marginTop: '2em'}}>
                            <div className="card" style={{width: '100%', padding: '20px'}}>
                                <p className='text-center mb-2'>Gérer les événements</p>
                                <button
                                    className='btn btn-secondary w-100 mb-2'
                                    onClick={() => setSection('ajouter')}
                                >Ajouter un événement
                                </button>
                                <button onClick={() => setSection('supprimer')} className='btn btn-secondary w-100'>Supprimer un événement</button>
                            </div>
                        </div>
                    :
                        null
            }
            {
                section === 'ajouter'
                    ?
                    <div style={{marginTop: '2em'}}>
                        <div className="card" style={{width: '100%', padding: '20px'}}>
                            <p className='text-center mb-2'>Ajouter un événement</p>
                            <div className="mb-3">
                                <label className="form-label">Titre</label>
                                <input
                                    name='title'
                                    className="form-control"
                                    value={inputEvent.title}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Texte (facultatif)</label>
                                <textarea
                                    name='text'
                                    className="form-control"
                                    value={inputEvent.text}
                                    onChange={handleInputChange}
                                    rows="3"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Date</label>
                                <DatePicker
                                    selected={startDate}
                                    locale="fr"
                                    onChange={(date) => {
                                        console.log(date)
                                        setStartDate(date)
                                    }}
                                    showTimeSelect
                                    dateFormat="d MMMM, yyyy HH:mm"
                                />
                            </div>
                            <button onClick={handleAddEvent} className='btn btn-primary w-100'>
                                Ajouter
                            </button>
                        </div>

                    </div>
                    :
                    null
            }
            {
                section === 'supprimer'
                    ?
                    <div style={{marginTop: '2em'}}>
                        <div className="card" style={{width: '100%', padding: '20px'}}>
                            <p className='text-center mb-2'>Supprimer un événement</p>
                            <ul className="list-group">
                                <EventsList />
                            </ul>
                        </div>

                    </div>
                    :
                    null
            }
        </>
    )
}

export default Events